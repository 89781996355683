import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import StoreLocatorContainer from 'containers/StoreLocatorContainer';
import { initializeSentry } from 'config/sentry';
import { initializeDatadog } from 'logging/datadog';
require('es6-promise').polyfill();

initializeSentry();
initializeDatadog();

window.storeLocatorConfig = function (props) {
  ReactDOM.render(
    <StoreLocatorContainer {...props} />,
    document.getElementById('storeLocator')
  );
};
