import { logWarn } from 'logging/log';
/**
 * This function calculates if an event should be to send to sentry
 * or if we want to exclude it.
 *
 * @param {Object} event Sentry event
 * @param {Object} eventMatcher Matcher settings
 * @param {string} [eventMatcher.type] The event type. For example: TypeError
 * @param {string} [eventMatcher.value] The event message. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.message] The event message in case is not an exception. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.file] The source file path or string. For example: http://bentobox.com/file.js or just file.js
 * @param {string} [eventMatcher.anyFrameFile] The source file path or string. For example: http://bentobox.com/file.js or just file.js, this will search on all the frames of the exception for this file.
 * @param {string} [eventMatcher.missingStackTrace] If set to true, the expectation is that the event doesn't have a stack trace, if set to false (the default), the event must have a stacktrace.
 *
 * @returns boolean
 */
export function excludeEvent(event, eventMatcher) {
  // Usage restrictions
  const matcherProperties = Object.getOwnPropertyNames(eventMatcher);
  if (
    matcherProperties.includes('file') &&
    matcherProperties.includes('missingStackTrace')
  ) {
    throw Error(
      'This two properties can not be set at the same time, please only use one: "file", "missingStackTrace"'
    );
  }
  if (
    matcherProperties.includes('file') &&
    matcherProperties.includes('anyFrameFile')
  ) {
    throw Error(
      'This two properties can not be set at the same time, please only use one: "file", "anyFrameFile"'
    );
  }

  try {
    if (event.exception) {
      let typeMatches = false;
      let typeAndValueMatches = false;
      let fileMatches = false;
      let passesStackTraceCheck = true;

      const sentryExcept =
        event.exception.values[event.exception.values.length - 1];

      if (eventMatcher.missingStackTrace && sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (!eventMatcher.missingStackTrace && !sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (eventMatcher.type) {
        typeMatches = sentryExcept.type.includes(eventMatcher.type);
        const valueMatches = eventMatcher.value
          ? sentryExcept.value.includes(eventMatcher.value)
          : false;
        typeAndValueMatches = typeMatches && valueMatches;
      }

      if (eventMatcher.file) {
        let frameIndex = sentryExcept.stacktrace?.frames.length - 1;
        let frame = sentryExcept.stacktrace?.frames[frameIndex];

        while ((frame?.filename ?? '') === '[native code]' && frameIndex >= 0) {
          frame = sentryExcept.stacktrace?.frames[frameIndex];
          frameIndex--;
        }
        if (frame) {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.file
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.file
          );
          fileMatches = filenameMatches || absPathMatches;
        }
        if (!eventMatcher.value) {
          return typeMatches && fileMatches;
        }
        return typeAndValueMatches && fileMatches;
      }

      if (eventMatcher.anyFrameFile) {
        let frameIndex = sentryExcept.stacktrace?.frames.length - 1;
        let frame = sentryExcept.stacktrace?.frames[frameIndex];

        while (frame) {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.anyFrameFile
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.anyFrameFile
          );
          fileMatches = filenameMatches || absPathMatches;
          frame = sentryExcept.stacktrace?.frames[frameIndex];
          frameIndex--;
        }
        if (!eventMatcher.value) {
          return typeMatches && fileMatches;
        }
        return typeAndValueMatches && fileMatches;
      }

      return typeAndValueMatches && passesStackTraceCheck;
    } else {
      if (eventMatcher.message && event.message) {
        return event.message.includes(eventMatcher.message);
      }
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function beforeSend(event) {
  const ignoreEvent = excludeEventMatcherList.some(matcher =>
    excludeEvent(event, matcher)
  );

  if (ignoreEvent) {
    // Extract exception details or fallback to a message
    const sentryExcept = event.exception?.values?.[
      (event.exception?.values.length ?? 1) - 1
    ] || {
      type: 'Message',
      value: event.message || 'Unknown exception'
    };

    // Log the exception for debugging
    logWarn(
      `[store-locator] Unhandled Exception: [${
        sentryExcept.type || 'Unknown Type'
      }][${sentryExcept.value || 'Unknown Value'}]`,
      { eventId: event.event_id, tags: event.tags }
    );

    return null;
  }
  return event;
}

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  {
    // https://bentobox.sentry.io/issues/4264389791/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'ReferenceError',
    value: "Can't find variable: badge",
    file: 'bubbakoos.com'
  },
  {
    // https://bentobox.sentry.io/issues/4264389791/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'ReferenceError',
    value: 'badge is not defined',
    file: 'bubbakoos.getbento.com'
  },
  {
    // https://bentobox.sentry.io/issues/6065207921
    type: 'TypeError',
    value: "undefined is not an object (evaluating 't.ssresp')",
    file: 'origin-secure-prod-radware.getbento.com'
  },
  {
    // https://bentobox.sentry.io/issues/4264389791/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'ReferenceError',
    value: "Can't find variable: _AutofillCallbackHandler"
  },
  {
    // https://bentobox.sentry.io/issues/4264389791/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'TypeError',
    value:
      'null is not an object (evaluating \'Object.prototype.hasOwnProperty.call(o,"telephone")\')'
  },
  {
    // https://bentobox.sentry.io/issues/4941702049
    type: 'TypeError',
    value:
      'null is not an object (evaluating \'Object.prototype.hasOwnProperty.call(e,"telephone")\')'
  },
  {
    // https://bentobox.sentry.io/issues/2703465161
    type: 'TypeError',
    value: 'Load failed'
  },
  {
    // https://bentobox.sentry.io/issues/6200903288
    type: 'SecurityError',
    value: 'The operation is insecure.',
    file: 'stormcaster.js'
  },
  {
    // https://bentobox.sentry.io/issues/6200903288
    type: 'Error',
    value: 'Expected at most 2 arguments for sendNativeMessage(), got 3'
  },
  {
    // https://bentobox.sentry.io/issues/5851087827
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'fireEvent')"
  },
  {
    // https://bentobox.sentry.io/issues/6171696329
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'setIsViewable')"
  },
  {
    // https://bentobox.sentry.io/issues/3243278736/events/latest/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value:
      "undefined is not an object (evaluating 'window.bentobox.overlayAlertComponent')"
  },
  {
    // https://bentobox.sentry.io/issues/3125991428/events/latest/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'planRemediations')",
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/1127627968/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'Error',
    value: 'Could not load "util".',
    file: 'maps.googleapis.com/maps/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/4407724961/?environment=production&project=1513824&referrer=jira_integration
    type: 'TypeError',
    value: 'Illegal invocation',
    file: 'vbpx.js'
  },
  {
    // https://bentobox.sentry.io/issues/3887665657/?project=1513824&referrer=jira_integration
    type: 'ChunkLoadError',
    value: 'Loading chunk',
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/3165737374/events/latest/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value:
      "undefined is not an object (evaluating 'AudioEye.compliance.planRemediations')",
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/3814098233/events/60f7bc0a0a2e44a9a8a950d3913ebdc8/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value: "Cannot set properties of null (setting 'href')",
    file: 'https://directed.extremepizza.com/sitewide_fix.js'
  },
  {
    // https://bentobox.sentry.io/issues/3814098233/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'TypeError',
    value: 'boxclass is null',
    file: 'https://directed.extremepizza.com/sitewide_fix.js'
  },
  {
    // https://bentobox.sentry.io/issues/3814098233/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'TypeError',
    value:
      "null is not an object (evaluating 'boxclass.href = 'https://www.extremepizza.com/store-locator'')",
    file: 'https://directed.extremepizza.com/sitewide_fix.js'
  },
  {
    // https://bentobox.sentry.io/share/issue/53e25d3103e44270af90e5539b8985b3/
    type: 'TypeError',
    value: 'mt is null',
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/1127820767/events/90a3711ae80b47a6b7da0b3dfec618b2/
    type: 'SyntaxError',
    value: "Unexpected token ':'",
    file: 'https://app-assets.getbento.com/alerts-component/'
  },
  {
    // https://bentobox.sentry.io/issues/1127820767/events/34ca120f5e7a4fb6b657ec6571c2597b/
    type: 'SyntaxError',
    value: "Unexpected token ':'",
    file: 'store-locator-bundle.js.map'
  },
  {
    // https://bentobox.sentry.io/issues/4981953146/events/cd04f2e691764f22a90290d24a07d2ea/
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: Timeout',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/1714426594
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'location')"
  },
  {
    // https://bentobox.sentry.io/issues/2431888935/
    type: 'Error',
    value: 'Could not load "marker".',
    file: 'maps.googleapis.com/maps/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/4123320788
    type: 'Error',
    value: 'Unhandled error. (undefined)'
  },
  {
    // https://bentobox.sentry.io/issues/1526214041
    type: 'Error',
    value: "Failed to find div with id 'vonageClick2callme'",
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/5709193807/
    type: 'Event',
    value: 'Non-Error promise rejection captured with keys',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/2431888935/
    type: 'Error',
    file: '/maps/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/5687469563/
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'hashchange')",
    file: 'bentobox.min.js'
  },
  {
    // https://bentobox.sentry.io/issues/6042327902/
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'bind')"
  },
  {
    // https://bentobox.sentry.io/issues/6014568430/events/latest/?environment=production&project=1513824&statsPeriod=30d
    type: 'RangeError',
    value: 'Maximum call stack size exceeded',
    anyFrameFile: 'https://polaris.truevaultcdn.com/static/polaris.js'
  },
  {
    // https://bentobox.sentry.io/issues/4264389791/events/c8f442d7fda942dfaa4fd99fcbf1367c/
    type: 'ReferenceError',
    value: 'badge is not defined',
    file: 'bubbakoos.com'
  },
  {
    // https://bentobox.sentry.io/issues/3243278736/events/288160ab6f6a4c19a591a19ec9166b6f/
    type: 'TypeError',
    value: "Cannot read properties of null (reading 'childNodes')",
    file: '<anonymous>'
  },
  {
    // https://bentobox.sentry.io/issues/2120378296/
    type: 'Error',
    value:
      'Attempt to use history.pushState() more than 100 times per 10 seconds',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/3966611566/events/d71d8a18c07342428c1405572a97e4ab/?project=1513824
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'getData')",
    file: 'mapbox-gl-geocoder/lib/index.js'
  },
  {
    // https://bentobox.sentry.io/issues/1399679334/
    type: 'Error',
    value: 'Could not load "infowindow".',
    file: 'maps.googleapis.com/maps/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/6200922930
    type: 'Error',
    value: 'Error: Could not load "marker".', // eslint-disable-line no-useless-escape
    file: 'maps.googleapis.com/maps-api-v3/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/6200922930
    type: 'TypeError',
    value: "Cannot read property 'planRemediations' of undefined",
    file: 'wsv3cdn.audioeye.com/static-scripts'
  },
  {
    // https://bentobox.sentry.io/issues/6200922930
    type: 'TypeError',
    value: 'Object.hasOwn is not a function',
    file: 'aegis.audioeye.com/assets/index.js'
  },
  {
    // https://bentobox.sentry.io/issues/5261523404/
    type: 'TypeError',
    value: "undefined is not an object (evaluating 'r.DomainData')",
    file: 'cdn.cookielaw.org'
  },
  {
    // https://bentobox.sentry.io/issues/5261523404/
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'DomainData')",
    file: 'cdn.cookielaw.org'
  },
  {
    // https://bentobox.sentry.io/issues/5645533043/
    type: 'Error',
    value: 'Invalid call to runtime.sendMessage(). Tab not found.'
  },
  {
    // https://bentobox.sentry.io/issues/5698602087/
    type: 'TypeError',
    value: 'this.Eg.close is not a function',
    file: 'maps.googleapis.com'
  },
  {
    // https://bentobox.sentry.io/issues/6113632475
    type: 'TypeError',
    value: "v[t] is not a function. (In 'v[t]()', 'v[t]' is undefined)",
    file: 'wsv3cdn.audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/5698602087/
    type: 'TypeError',
    value: 'this.Eg.close is not a function',
    file: 'maps.googleapis.com'
  }
];
