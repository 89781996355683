import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import kdbush from 'kdbush';
import geokdbush from 'geokdbush';

import LocationsContainer from 'containers/LocationsContainer';
import MapContainer from 'containers/MapContainer';
import {
  ScreenReaderMainTitle,
  StoreLocationContainer
} from 'styles/styledComponents';

import {
  defaultLat,
  defaultLng,
  displayMoreInfo,
  displaySearch,
  defaultZoomLevel
} from 'constants/defaultMapProps';
import locationData from 'constants/locationData';
import GlobalStyle from 'styles/GlobalStyle';

const MAX_DISTANCE = 200;

function getLat(props) {
  try {
    return parseFloat(props.settings.default_map_position.default_lat);
  } catch (e) {
    return parseFloat(defaultLat);
  }
}

function getLng(props) {
  try {
    return parseFloat(props.settings.default_map_position.default_lng);
  } catch (e) {
    return parseFloat(defaultLng);
  }
}

function getZoom(props) {
  try {
    return props.settings.default_map_position.zoom_level;
  } catch (e) {
    return defaultZoomLevel;
  }
}
export default class StoreLocatorContainer extends React.Component {
  state = {
    activeId: null,
    center: {
      lat: getLat(this.props),
      lng: getLng(this.props)
    },
    zoom: getZoom(this.props),
    searchResults: {},
    locations: [],
    filteredLocations: [],
    browserPosition: {},
    locationNearby: true
  };

  handleToggleOpen = id => {
    this.setState({ activeId: id });
  };

  handleToggleClose = () => {
    this.setState({ activeId: null });
  };

  updateCenter = (lat, lng) => {
    this.setState({ center: { lat, lng } });
  };

  updateZoom = level => {
    this.setState({ zoom: level });
  };

  getBrowserLocationLatitude = () => {
    return this.state.browserPosition.coords.latitude;
  };

  getBrowserLocationLongitude = () => {
    return this.state.browserPosition.coords.longitude;
  };

  getBrowserLocation = position => {
    this.setState({ browserPosition: position });
    const searchResults = {
      geometry: {
        location: {
          lat: this.getBrowserLocationLatitude,
          lng: this.getBrowserLocationLongitude
        }
      }
    };
    this.updateSearchResults(searchResults);
    this.updateActiveMarker();
  };

  getBrowserLocationError = error => {
    console.error(error);
  };

  askBrowserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getBrowserLocation,
        this.getBrowserLocationError
      );
    }
  };

  removeDistances = () => {
    const { locations } = this.state;
    this.setState({
      locations: locations.map(location => {
        delete location.distance;
        return location;
      })
    });
  };

  revertToDefault = () => {
    const { settings } = this.props;
    this.setState({ locations: this.state.filteredLocations });
    this.removeDistances();
    this.handleToggleClose();
    let lat = settings.default_map_position.default_lat;
    if (!lat) {
      lat = defaultLat;
    }
    let lng = settings.default_map_position.default_lng;
    if (!lng) {
      lng = defaultLng;
    }
    this.updateCenter(parseFloat(lat), parseFloat(lng));
    let zoomLevel = settings.default_map_position.zoom_level;
    if (!zoomLevel) {
      zoomLevel = defaultZoomLevel;
    }
    this.updateZoom(zoomLevel);
    this.setState({ locationNearby: true });
  };

  addDistanceToLocations = locations => {
    const { searchResults } = this.state;

    const locationsWithDistances = locations.map(location => {
      const { lat, lng } = location;
      const searchLat = searchResults.geometry.location.lat();
      const searchLng = searchResults.geometry.location.lng();
      const distance = geokdbush.distance(searchLng, searchLat, lng, lat);
      const miles = parseFloat((distance * 0.62137).toFixed(2));
      location.distance = miles;
      return location;
    });
    return locationsWithDistances;
  };

  updateLocationsOrder = unorderedLocations => {
    return unorderedLocations.sort((a, b) =>
      a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0
    );
  };

  updateActiveMarker = () => {
    const { locations, searchResults } = this.state;
    this.removeDistances();
    const index = kdbush(
      locations,
      p => p.lat,
      p => p.lng
    );

    let locationsByDistance = geokdbush.around(
      index,
      searchResults.geometry.location.lat(),
      searchResults.geometry.location.lng(),
      locations.length
    );
    locationsByDistance = this.addDistanceToLocations(locationsByDistance);
    const locationNearby = !!locationsByDistance.some(
      location =>
        location.distance && location.distance <= MAX_DISTANCE && location
    );
    this.setState({ locationNearby });
    if (locationNearby) {
      const orderedByDistance = this.updateLocationsOrder(locationsByDistance);

      const activeLat = parseFloat(orderedByDistance[0].lat);
      const activeLng = parseFloat(orderedByDistance[0].lng);

      this.handleToggleOpen(orderedByDistance[0].id);
      this.updateCenter(activeLat, activeLng);
      this.updateZoom(15);
      this.setState({ locations: orderedByDistance });
    }
  };

  updateSearchResults = searchResults => {
    this.setState({ searchResults });
    if (this.state.searchResults.geometry) {
      this.updateActiveMarker();
    }
  };

  convertSettings = () => {
    const { settings } = this.props;

    const additionalLocationOptions =
      settings.additional_location_options || {};

    return {
      defaultLat: settings.default_lat,
      defaultLng: settings.default_lng,
      displayMoreInfo: settings.display_more_info,
      displaySearch: settings.display_search,
      defaultZoom: settings.zoom_level,
      displayMultiButton:
        additionalLocationOptions.display_additional_location_button,
      multiButton: additionalLocationOptions.multibutton
    };
  };

  filterLocations = () => {
    const { locations } = this.props;
    const filteredLocations = locations.filter(location => {
      return parseFloat(location.lat) !== 0 && parseFloat(location.lng) !== 0;
    });
    this.setState({
      filteredLocations: JSON.parse(JSON.stringify(filteredLocations))
    });
    return filteredLocations;
  };
  /* istanbul ignore next */

  getPoints = () => {
    const { locations } = this.state;
    return locations.map(location => {
      return [location.lat, location.lng];
    });
  };

  componentDidMount() {
    this.setState({ locations: this.filterLocations() });
    this.askBrowserLocation();
  }

  render() {
    const { locations, center, zoom, activeId } = this.state;
    const { markerIcon, mapStyles, chowNowId } = this.props;

    return (
      <>
        <StoreLocationContainer
          id="storeLocatorContainer"
          className="storeLocator"
          ref={divElement => (this.divElement = divElement)}
        >
          <GlobalStyle />
          <ScreenReaderMainTitle>Locations</ScreenReaderMainTitle>
          <LocationsContainer
            handleToggleClose={this.handleToggleClose}
            handleToggleOpen={this.handleToggleOpen}
            locations={locations}
            revertToDefault={this.revertToDefault}
            settings={this.convertSettings()}
            updateCenter={this.updateCenter}
            updateSearchResults={this.updateSearchResults}
            updateZoom={this.updateZoom}
            locationNearby={this.state.locationNearby}
            chowNowId={chowNowId}
            center={center}
          >
            <MapContainer
              activeId={activeId}
              center={center}
              className="mapContainer"
              defaultStyles={mapStyles}
              locations={locations}
              markerIcon={markerIcon}
              handleToggleClose={this.handleToggleClose}
              handleToggleOpen={this.handleToggleOpen}
              settings={this.convertSettings()}
              zoom={zoom}
            />
          </LocationsContainer>
          <MediaQuery query="(min-width: 768px)">
            <MapContainer
              activeId={activeId}
              center={center}
              className="mapContainer"
              defaultStyles={mapStyles}
              locations={locations}
              markerIcon={markerIcon}
              handleToggleClose={this.handleToggleClose}
              handleToggleOpen={this.handleToggleOpen}
              settings={this.convertSettings()}
              zoom={zoom}
            />
          </MediaQuery>
        </StoreLocationContainer>
      </>
    );
  }
}

StoreLocatorContainer.propTypes = {
  chowNowId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  markerIcon: PropTypes.string,
  mapStyles: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.object
};

StoreLocatorContainer.defaultProps = {
  chowNowId: null,
  locations: locationData,
  settings: {
    additional_location_options: {},
    default_lat: defaultLat,
    default_lng: defaultLng,
    display_more_info: displayMoreInfo,
    display_search: displaySearch,
    zoom_level: defaultZoomLevel
  }
};
